import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import Schema from './schema';
import { SocketTypes } from '../../../App/Socket/socket_types';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------

// HOW DO I USE BASE ACTION HANDLERS?

const updatePackage = (state, { payload }) => {
    const byId = { ...state.byId };
    const allIds = [...state.allIds];
    if (payload?._id) {
        if (!state.allIds.includes(payload._id)) {
            allIds.push(payload._id);
        }
        byId[payload._id] = { ...payload };
    }
    return { ...state, byId, allIds };
};

const getSourceCodeSuccess = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], sourceCode: response, loading: false };
    return { ...state, byId };
};

const getSourceCodeFailure = (state, { id }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        sourceCode: { error: 'error retrieving source code, please refresh page to try again' },
        loading: false,
    };
    return { ...state, byId };
};

const updateCodeSuccess = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id].sourceCode = { ...byId[id].sourceCode, ...response };
    return { ...state, byId };
};

const updateCodeFailure = (state, { id }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        sourceCode: { error: 'error modifying source code, please refresh page to try again' },
        loading: false,
    };
    return { ...state, byId };
};

const getDependenciesSuccess = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], dependencies: response, loading: false };
    return { ...state, byId };
};

const getDependenciesFailure = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], dependencies: response, loading: false };
    return { ...state, byId };
};

const updateDependenciesSuccess = (state, { id }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        dependencies: { error: 'error modifying dependencies, please refresh page to try again' },
        loading: false,
    };
    return { ...state, byId };
};

const updateDependenciesFailure = (state, { id }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        dependencies: { error: 'error modifying dependencies, please refresh page to try again' },
        loading: false,
    };
    return { ...state, byId };
};

const updateEnvRequest = (state, { id, form }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], env: form, loading: false };
    return { ...state, byId };
};

const getEnvSuccess = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], env: response, loading: false };
    return { ...state, byId };
};

const getEnvFailure = (state, { id }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        env: { error: 'error retrieving environment variables, please refresh page to try again' },
        loading: false,
    };
    return { ...state, byId };
};

const updateEnvSuccess = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], env: response, loading: false };
    return { ...state, byId };
};

const updateEnvFailure = (state, { id }) => {
    const byId = { ...state.byId };
    byId[id] = {
        ...byId[id],
        env: { error: 'error modifying environment variables, please refresh page to try again' },
        loading: false,
    };
    return { ...state, byId };
};

const commitPackageImageSuccess = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], loading: false };
    return { ...state, byId };
};

const commitPackageImageFailure = (state, { id, response }) => {
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], loading: false };
    return { ...state, byId };
};

const updateLocal = (state, { id, updateObj }) => {
    // update redux state locally without sending requests (does not set loading)
    const byId = { ...state.byId };
    byId[id] = { ...byId[id], ...updateObj };
    return { ...state, byId };
};

const startDashAppRequest = (state, { id, dashApp }) => {
    const byId = { ...state.byId };
    if (byId[id]) {
        byId[id] = { 
            ...byId[id], 
            dashApps: { 
                ...(byId[id].dashApps || {}), 
                [dashApp]: { loading: true } 
            } 
        };
    }
    return { ...state, byId };
};

const startDashAppSuccess = (state, { id, dashApp, response }) => {
    const byId = { ...state.byId };
    if (byId[id]) {
        byId[id] = { 
            ...byId[id], 
            dashApps: { 
                ...(byId[id].dashApps || {}), 
                [dashApp]: { 
                    loading: false, 
                    port: response.dashPort,
                    status: response.status,
                    error: null
                } 
            } 
        };
    }
    return { ...state, byId };
};

const startDashAppFailure = (state, { id, dashApp, errors }) => {
    const byId = { ...state.byId };
    if (byId[id]) {
        byId[id] = { 
            ...byId[id], 
            dashApps: { 
                ...(byId[id].dashApps || {}), 
                [dashApp]: { 
                    loading: false, 
                    error: errors.message || 'Failed to start Dash application'
                } 
            } 
        };
    }
    return { ...state, byId };
};

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('package', {
    uploadRequest: ['form'],
    uploadSuccess: ['form', 'response'],
    uploadFailure: ['form', 'errors'],

    getSourceCodeRequest: ['id'],
    getSourceCodeSuccess: ['id', 'response'],
    getSourceCodeFailure: ['id', 'errors'],

    updateCodeRequest: ['id', 'form'],
    updateCodeSuccess: ['id', 'response'],
    updateCodeFailure: ['id', 'form', 'errors'],

    getDependenciesRequest: ['id'],
    getDependenciesSuccess: ['id', 'response'],
    getDependenciesFailure: ['id', 'errors'],

    updateDependenciesRequest: ['id', 'form'],
    updateDependenciesSuccess: ['id', 'response'],
    updateDependenciesFailure: ['id', 'form', 'errors'],

    getEnvRequest: ['id'],
    getEnvSuccess: ['id', 'response'],
    getEnvFailure: ['id', 'errors'],

    updateEnvRequest: ['id', 'form'],
    updateEnvSuccess: ['id', 'response'],
    updateEnvFailure: ['id', 'form', 'errors'],

    commitPackageImageRequest: ['id'],
    commitPackageImageSuccess: ['id', 'response'],
    commitPackageImageFailure: ['id', 'errors'],

    updateLocal: ['id', 'updateObj'],

    startDashAppRequest: ['id', 'dashApp'],
    startDashAppSuccess: ['id', 'dashApp', 'response'],
    startDashAppFailure: ['id', 'dashApp', 'errors'],

});
export { Creators as Actions, Types };

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('package', INITIAL_STATE, Schema, Types);
const additionalReducers = apiHandlers(['UPLOAD', 'GET_SOURCE_CODE', 'UPDATE_CODE', 'COMMIT_PACKAGE_IMAGE', 'START_DASH_APP'], Types, {
    [SocketTypes.PACKAGE_UPDATE]: updatePackage,

    [Types.GET_SOURCE_CODE_SUCCESS]: getSourceCodeSuccess,
    [Types.GET_SOURCE_CODE_FAILURE]: getSourceCodeFailure,

    [Types.UPDATE_CODE_SUCCESS]: updateCodeSuccess,
    [Types.UPDATE_CODE_FAILURE]: updateCodeFailure,

    [Types.GET_ENV_SUCCESS]: getEnvSuccess,
    [Types.GET_ENV_FAILURE]: getEnvFailure,

    [Types.UPDATE_ENV_REQUEST]: updateEnvRequest,
    [Types.UPDATE_ENV_SUCCESS]: updateEnvSuccess,
    [Types.UPDATE_ENV_FAILURE]: updateEnvFailure,

    [Types.GET_DEPENDENCIES_SUCCESS]: getDependenciesSuccess,
    [Types.GET_DEPENDENCIES_FAILURE]: getDependenciesFailure,

    [Types.UPDATE_DEPENDENCIES_SUCCESS]: updateDependenciesSuccess,
    [Types.UPDATE_DEPENDENCIES_FAILURE]: updateDependenciesFailure,

    [Types.COMMIT_PACKAGE_IMAGE_SUCCESS]: commitPackageImageSuccess,
    [Types.COMMIT_PACKAGE_IMAGE_FAILURE]: commitPackageImageFailure,

    [Types.START_DASH_APP_REQUEST]: startDashAppRequest,
    [Types.START_DASH_APP_SUCCESS]: startDashAppSuccess,
    [Types.START_DASH_APP_FAILURE]: startDashAppFailure,

    [Types.UPDATE_LOCAL]: updateLocal,

    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('packages'),

    

    
});

export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
