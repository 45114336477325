import React from 'react';

const ComparisonKpi = React.lazy(() => import('./comparisonKpi'));
const ComparisonKpiMapping = React.lazy(() => import('./comparisonKpi/mapping'));

const ImageKpi = React.lazy(() => import('./imageKpi'));
const ImageKpiMapping = React.lazy(() => import('./imageKpi/mapping'));

const MapUnit = React.lazy(() => import('./mapUnit'));
const MapUnitMapping = React.lazy(() => import('./mapUnit/mapping'));

const Markdown = React.lazy(() => import('./markdown'));
const MarkdownMapping = React.lazy(() => import('./markdown/mapping'));

const Network = React.lazy(() => import('./network'));
const NetworkMapping = React.lazy(() => import('./network/mapping'));

const Html = React.lazy(() => import('./html'));
const HtmlMapping = React.lazy(() => import('./html/mapping'));

const Editable = React.lazy(() => import('./editable'));
const EditableMapping = React.lazy(() => import('./editable/mapping'));

const Dash = React.lazy(() => import('./dash'));
const DashMapping = React.lazy(() => import('./dash/mapping'));

export const customUnitSubtypes = {
    comparisonKpi: {
        component: ComparisonKpi,
        mapping: ComparisonKpiMapping,
    },
    imageKpi: {
        component: ImageKpi,
        mapping: ImageKpiMapping,
    },
    mapUnit: {
        component: MapUnit,
        mapping: MapUnitMapping,
    },
    markdown: {
        component: Markdown,
        mapping: MarkdownMapping,
    },
    network: {
        component: Network,
        mapping: NetworkMapping,
    },
    html: {
        component: Html,
        mapping: HtmlMapping,
    },
    editable: {
        component: Editable,
        mapping: EditableMapping,
    },
    dash: {
        component: Dash,
        mapping: DashMapping,
    },
};
