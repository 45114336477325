import React from 'react';
import { ProjectProtectedRoute } from '../../helpers/router';
import PackageDisplay from './Display';

export default [
    {
        path: ':packageId',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ package: 'view' }}>
                <PackageDisplay />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: '/env',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ package: 'edit' }}>
                <PackageDisplay />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: '/dependencies',
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ package: 'edit' }}>
                <PackageDisplay />
            </ProjectProtectedRoute>
        ),
    },
    {
        path: ':packageId/dash/:dashApp',  // Add route for viewing a specific dash app
        exact: false,
        element: (
            <ProjectProtectedRoute requiredRole={{ package: 'view' }}>
                <PackageDisplay />
            </ProjectProtectedRoute>
        ),
    },
];
