import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ModelModel } from '../../_models';
import { makeButtonOptions } from './helpers';

import { Button, CollapsibleSection, Loader, ScrollArea } from '../../../shared';
import ButtonWithOptions from '../ButtonWithOptions';
import SidePanelButton from '../../../shared/SidePanel/SidePanelButton';
import ModelInput from '../ModelInput';
import ModelHistory from './ModelHistory';

import './styles.scss';

export default function ModelPanel(props) {
    const { modelId, closeModelPanel, closeButtonStyle, disabled, noEditLink, onRun, runModel: runModelProp } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const isPublic = useSelector((state) => state.app.public);

    const models = useSelector((state) => state.models);
    const modelRuns = useSelector((state) => state.modelRuns);
    useEffect(() => {
        // load the model if it doesn't exist in state
        // note: we're making use of the fact that baseReducer will populate an empty object on baseShowRequest to ensure it's not requested multiple times
        if (!models.byId[modelId]) dispatch(ModelModel.show(modelId));
    }, [dispatch, models, modelId]);

    const model = models.byId[modelId];

    useEffect(() => {
        if (model && model.inputs && !model.loading && !model.runs) {
            dispatch(ModelModel.getRuns(model._id, { limit: 20 }));
        }
    }, [dispatch, model]);

    const updateLocalModelJobId = (jobId) => {
        dispatch(ModelModel.updateLocal(modelId, { jobId }));
    };

    const inputs = model && model.inputs;
    const primaryInputs = inputs ? inputs.filter((input) => input.display?.modelPanel === 'primary') : [];
    const secondaryInputs = inputs ? inputs.filter((input) => input.display?.modelPanel === 'secondary') : [];

    const runModel = runModelProp || (() => dispatch(ModelModel.run(model._id)));

    const handleInputsChange = (newInputs) => {
        dispatch(ModelModel.updateLocal(modelId, { inputs: newInputs }));
    };
    if (!modelId || !model) {
        return (
            <div className="ModelPanel">
                {closeModelPanel && (
                    <SidePanelButton onClick={closeModelPanel} icon="angle-right" style={closeButtonStyle} />
                )}
                <Loader type="content" loader="content" />
            </div>
        );
    }

    return (
        <div className="ModelPanel">
            {closeModelPanel && (
                <SidePanelButton onClick={closeModelPanel} icon="angle-right" style={closeButtonStyle} />
            )}
            <div className="ModelPanel__title">Model</div>
            <ButtonWithOptions
                icon="brain"
                name={model.name}
                options={makeButtonOptions(model, {
                    noEditLink,
                    handleInputsChange,
                    navigate,
                    projectId,
                    isPublic,
                })}
                selected
                loading={model.running}
            />
            <CollapsibleSection line expanded title="Inputs">
                <ScrollArea>
                    {primaryInputs.map((input) => (
                        <ModelInput
                            key={input.varName}
                            inputs={inputs}
                            input={input}
                            onInputsChange={handleInputsChange}
                            disabled={disabled}
                        />
                    ))}
                </ScrollArea>
            </CollapsibleSection>
            <Button blue disabled={disabled || !model || model.running} onClick={onRun || runModel}>
                {model && model.running ? 'Running Model' : model.runButtonText || 'Run Model'}
            </Button>
            {secondaryInputs.length > 0 && (
                <CollapsibleSection line title="Advanced Settings">
                    <ScrollArea>
                        {secondaryInputs.map((input) => (
                            <ModelInput
                                key={input.varName}
                                inputs={inputs}
                                input={input}
                                onInputsChange={handleInputsChange}
                                disabled={disabled}
                            />
                        ))}
                    </ScrollArea>
                </CollapsibleSection>
            )}
            <CollapsibleSection line expanded title="Model History">
                <ScrollArea>
                    {model && (
                        <ModelHistory
                            model={model}
                            modelRuns={modelRuns}
                            onSelect={updateLocalModelJobId}
                            disabled={disabled}
                            isPublic={isPublic}
                        />
                    )}
                </ScrollArea>
            </CollapsibleSection>
        </div>
    );
}

ModelPanel.propTypes = {
    closeModelPanel: PropTypes.func,
    closeButtonStyle: PropTypes.shape({}),
    disabled: PropTypes.bool,
    noEditLink: PropTypes.bool,
    modelId: PropTypes.string,
    onRun: PropTypes.func,
    runModel: PropTypes.func,
};
ModelPanel.defaultProps = {
    closeModelPanel: null,
    closeButtonStyle: {},
    disabled: false,
    modelId: '',
    noEditLink: false,
    onRun: null,
    runModel: null,
};
